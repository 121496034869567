header {
  min-height: 90px;
  z-index: 9;
}

//TODO uit db halen! responsive en header!
app-header .navbar a.navbar-brand, app-header .navbar-bg a.navbar-brand {
  width: auto !important;
  height: auto !important;
  background: none !important;
}

.navbar {
  height: 90px;

  .navbar-brand {
    img {
      height: 80px;
    }
  }

  ul li a {
    color: #fefefe;
    font-weight: 400;
    padding-bottom: 25px;
    font-size: 29px;
    font-family: "Quicksand-Regular";
  }
}

.home-header-image {
  height: 360px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  font-family: "Quicksand-Regular";

  .home-header-image-text {
    position: absolute;
    bottom: 40px;
    width: 100%;

    .intro-content {
      margin: auto;
      text-align: center;

      h1 {
        color: #fefefe;
        line-height: 0.9;
        font-weight: 300;
        font-size: 82px;
      }
    }
  }
}

.navbar-bg {
  background-color: #9a9595;
}

.no-image {
  height:75px;
}
