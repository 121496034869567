/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import "ngx-toastr/toastr";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "global";
@import "header";
@import "footer";
@import "steps";
@import "responsive";

@font-face {
  font-family: 'Quicksand-Regular'; /*You can use whatever name that you want*/
  src: url('assets/Quicksand-VariableFont_wght.ttf');
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.form-control.is-invalid {
  background-image: none;
}
