/* ================== FOOTER ================= */
html, body {
  height: 100%;
  margin: 0;
  font-family: "Quicksand-Regular", sans-serif;
}

.wrapper {
  min-height: 100%;
  margin-bottom: -90px;
}

footer, .push {
  height: 90px !important;
}

div.errorMessage {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
/* =============== NEXT BUTTONS ============== */

.flex-navigation {
  margin-top: 40px;
  width: auto;
  cursor: pointer;

  .nav-button-next-only {
    text-decoration: none;
    background-color: #9a9595;
    color: #f0f0f0;
    padding: 20px 25px;
    margin-right: -5px;
    border-radius: 20px;
    font-family: "Quicksand-Regular";
    font-size: 24px;
    font-weight: 400;
    display: inline-block;
    transition: all 300ms ease-in-out;

    i {
      color: #f0f0f0;
      margin-right: 10px;
      transition: all 300ms ease-in-out;
    }
  }

  .nav-button-prev {
    background: #f0f0f0;
    padding: 20px 25px;
    margin-right: -5px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-family: "Quicksand-Regular", sans-serif;
    font-size: 24px;
    font-weight: 400;
    display: inline-block;
    transition: all 300ms ease-in-out;


    i {
      color: #9a9595;
      line-height: 1;
      transition: all 300ms ease-in-out;
    }
  }

  .nav-button-next {
    text-decoration: none;
    background-color: #9a9595;
    color: #f0f0f0;
    padding: 20px 25px;
    margin-right: -5px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-family: "Quicksand-Regular", sans-serif;
    font-size: 24px;
    font-weight: 400;
    display: inline-block;
    transition: all 300ms ease-in-out;

    i {
      color: #f0f0f0;
      margin-right: 15px;
      transition: all 300ms ease-in-out;
    }
  }
}

.pdf-button-download {
  width: 60%
}

.bedankt-buttons {
  min-width: 82%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.flex-navigation:hover {
  .nav-button-next-only {
    background-color: #f0f0f0;
    color: #9a9595;

    i {
      color: #9a9595;
    }
  }

  .nav-button-prev {
    background-color: #9a9595;
    color: #f0f0f0;

    i {
      color: #f0f0f0;
    }
  }

  .nav-button-next {
    background-color: #f0f0f0;
    color: #9a9595;

    i {
      color: #9a9595;
    }
  }
}



i {
  text-rendering: optimizeLegibility !important;
}

.span-disabled {
  pointer-events: none;
}

div.form-row.hiderow {
  height: 0px;
}
.hide-for-desktop {
  display: none;
}
