@media screen and (max-width: 41.6875em), screen and (max-width: 61.9375em) {

  header {
    min-height: auto;
  }

  .home-header-image {
    margin-top: 60px !important;
    height: 120px !important;

    .home-header-image-text {
      bottom: 13px !important;
      .intro-content {
        h1 {
          font-size: 32px;
        }
      }
    }
  }

  .navbar {
    padding: 0px !important;
    height: auto !important; //Zodat navbar niet verspringt bij openen menu
  }
  .navbar .navbar-brand img {
    height: 50px !important;
    padding-left: 10px;
    padding-top: 10px;
  }

  .navbar-nav {
    margin-bottom: 10px;
  }

  //alleen voor mobiel!
  .navbar ul li a {
    font-weight: 400;
    padding-bottom: inherit !important;
    font-size: 1rem;
  }

  .hide-for-small-only {
    display: none !important;
  }

  .hide-for-desktop {
    display: table;
  }

  h1 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1rem;
  }

  .navbar-collapse {
    background-color: #9a9595;
  }

}
