.app-footer {
  padding: 0;
  height: 90px;
  font-family: "Quicksand-Regular", sans-serif;

  .main-footer {
    height: 90px;
  }

  nav {
    margin-top: 26px;
    display: block;

    ul {
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      display: flex;
      list-style: none;
      font-size: 1.6rem;
      line-height: 1.3;
      color: #303636;
    }
    li {
      width: 33%;
      text-align: center;
      margin-bottom: 1.5rem;
      font-size: inherit;
      padding: 0;
    }

    a {
      font-size: 20px;
      font-weight: 700;
      color: #fff;
    }

    a:hover {
      text-decoration: none;
      color: black;
    }

    .fa-angle-right {
      position: absolute;
      font-size: 26px;
      margin-left: 10px;
      top: 5px;
    }
  }

  .footer-logo-img {
    height: 75px;
    margin-top: 7.5px;

    span.helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    img {
      max-height: 75px;
      max-width: 100%;
    }
  }
}
