.steps-container {
  padding-left: -15px;
  margin: -15px;

  .steps-header {
    background-color: #f7c214;
    cursor: auto;
    position: relative;
    width: 100%;
    padding: 20px 40px 20px 15px;
    border-radius: 10px;

    .steps-title {
      color: #fefefe;
      font-size: 28px;
      font-weight: 400;
    }
  }

  .steps-header::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    bottom: -12px;
    border-style: solid;
    border-width: 15px 15px 0;
    border-color: #f7c214 transparent transparent;
    margin: 0 auto;
  }

  .steps-list {
    margin-top: 20px;
    opacity: 1;
    transition: all 300ms ease-in-out;
    font-family: 'Quicksand-Regular', sans-serif;

    .steps-step {
      width: 100%;
      background: #f0f0f0;
      padding: 20px 15px;

      .steps-name {
        line-height: 1.3;
        color: #3c3c3c;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.8px;
        text-align: left;
      }
    }

    .steps-active {
      background-color: #12c1d6;
      position: relative;
      width: calc(100% + 20px);
      left: -10px;
      height: 100%;
      top: 0;
      content: "";
      border-radius: 10px;
      padding-left: 25px;
      padding-right: 25px;

      .steps-name {
        color: #ffffff;
      }
    }
  }
}
